import React from "react"
import Layout from "../components/layout/Layout";
import { Link } from "gatsby"
import Img from 'gatsby-image'
import { graphql } from "gatsby"
import { Navigation } from "../components/navigation/Navigation";
import { HeaderSize, ParallaxHeader } from "../components/layout/ParallaxHeader";
import { HeaderInfo } from "../components/layout/HeaderInfo";
import { Container } from "../components/layout/Container";
import { workPageData } from "../content/pageData/workPageData";
import { SquareIcon } from "../components/layout/icons/SquareIcon";
import { BlobThree } from "../components/layout/blobs/BlobThree";
import { BlobOne } from "../components/layout/blobs/BlobOne";
import { BlobTwo } from "../components/layout/blobs/BlobTwo";

type PageData = {
    data: {
        site: {
            siteMetadata: {
                title: string;
                description: string;
            }
        }
        rrCard: {
            childImageSharp: any;
        }
        rrfbCard: {
            childImageSharp: any;
        }
        ccCard: {
            childImageSharp: any;
        }
        htCard: {
            childImageSharp: any;
        }
        booomCard: {
            childImageSharp: any;
        }
        rr2Card: {
            childImageSharp: any;
        }
        suCard: {
            childImageSharp: any;
        }
        sssCard: {
            childImageSharp: any;
        }
        sghCard: {
            childImageSharp: any;
        }
        allMarkdownRemark: {
            edges: [{
                node: {
                    fields: {
                        slug: string
                    }
                    frontmatter: {
                        title: string
                    }
                }
            }]
        }
    }
}

export default ({ data }: PageData) => {
    let delay = 0;
    const title = workPageData.title;
    return (
        <Layout
            pageTitle={workPageData.metaPageTitle}
            keywords={workPageData.metaKeywords}
            description={workPageData.metaDescription}
            url={"work"}
        >
            <Navigation />
            <ParallaxHeader size={HeaderSize.MEDIUM}>
                <HeaderInfo
                    title={title}
                    className="our-games centered-header"
                />
            </ParallaxHeader>
            <Container className="work-container work-page">
                <div className="blob-container">
                    <BlobOne fill={"#080a2e08"} />
                    <BlobThree fill={"#080a2e08"} />
                </div>
                <div className={`products`}>
                    {
                        workPageData.games.map((product, index) => {
                            delay += 50;

                            return (
                                <Link to={`/work/${product.url}`} key={product.image.name} className="work-card" data-sal="slide-up" data-sal-delay={delay}>
                                    <Img fluid={data[product.image.name].childImageSharp.fluid} alt={product.image.alt} />
                                </Link>
                            );
                        })
                    }
                </div>
            </Container>
            <Container className="archived-header">
                <SquareIcon fill="#080a2e" className="left" />
                <div className="left-line" />
                <h2>Archived Games</h2>
                <div className="right-line" />
                <SquareIcon fill="#080a2e" className="right" />
            </Container>
            <Container className="work-container work-page lower">
                <div className="blob-container">
                    <BlobThree fill={"#080a2e08"} />
                    <BlobTwo fill={"#080a2e08"} />
                </div>
                <div className={`products`}>
                    {
                        workPageData.archived.map((product, index) => {
                            delay += 50;

                            return (
                                <Link to={`/work/${product.url}`} key={product.image.name} className="work-card" data-sal="slide-up" data-sal-delay={delay}>
                                    <Img fluid={data[product.image.name].childImageSharp.fluid} alt={product.image.alt} />
                                </Link>
                            );
                        })
                    }
                </div>
            </Container>
        </Layout>
    );
};



export const query = graphql`
    query {
        site {
            siteMetadata {
                title
                description
            }
        }
        rrCard: file(relativePath: { eq: "assets/images/work/rr1_card.png" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        rrfbCard: file(relativePath: { eq: "assets/images/work/rrfb_card.png" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        ccCard: file(relativePath: { eq: "assets/images/work/cc_card.png" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        htCard: file(relativePath: { eq: "assets/images/work/hyper_tap_card.png" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        booomCard: file(relativePath: { eq: "assets/images/work/booom_card.png" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        rr2Card: file(relativePath: { eq: "assets/images/work/rr2_card.png" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        suCard: file(relativePath: { eq: "assets/images/work/su_card.png" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        sssCard: file(relativePath: { eq: "assets/images/work/sss_card.png" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        sghCard: file(relativePath: { eq: "assets/images/work/sgh_card.png" }) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`